import React, { useState, useRef, useEffect } from "react";
import { Listbox } from "@headlessui/react";
import { FaUserAlt } from "react-icons/fa";

const AdultsDropdown = ({ setGuestCount }) => {
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const increaseCount = () => {
    if (count < 3) {
      setCount(count + 1);
    }
  };

  const decreaseCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleConfirm = () => {
    setGuestCount(count);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <Listbox as="div">
        {({ open }) => (
          <>
            <Listbox.Button
              className="relative h-12 w-full min-w-[120px] border border-accent rounded-md font-semibold text-sm bg-white text-primary cursor-pointer pl-2 pr-4 flex items-center justify-between transition-all duration-300 overflow-hidden"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="truncate">
                {count > 0 ? `${count} Misafir` : "Misafir Seçin"}
              </span>
              <FaUserAlt className="h-4 w-4 text-primary" />
            </Listbox.Button>
            {isOpen && (
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 min-w-[120px] w-64 border border-accent bg-white rounded-md shadow-lg transition-all duration-300 overflow-hidden">
                <div className="flex flex-col p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <button
                      className="w-8 h-8 bg-accent text-white rounded-full border border-accent flex items-center justify-center hover:bg-accent-dark transition-colors"
                      onClick={decreaseCount}
                      type="button"
                      id="decrease"
                    >
                      -
                    </button>
                    <span className="text-primary font-semibold text-lg">
                      {count}
                    </span>
                    <button
                      className="w-8 h-8 bg-accent text-white rounded-full border border-accent flex items-center justify-center hover:bg-accent-dark transition-colors"
                      onClick={increaseCount}
                      type="button"
                      id="increase"
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="bg-accent hover:bg-accent-dark text-white font-bold text-sm py-2 px-4 rounded-md transition-colors"
                    onClick={handleConfirm}
                    type="button"
                    id="Okay"
                  >
                    Tamam
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default AdultsDropdown;
