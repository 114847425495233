import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zv3dv05",
        "template_u1qjrsp",
        e.target,
        "e-u0_pSs8ercB6Gjl"
      )
      .then(
        (result) => {
          setIsSent(true);
          setFormData({ name: "", email: "", message: "" });
        },
        (error) => {
          setIsError(true);
        }
      );
  };

  return (
    <section className="font-tertiary bg-[#F6F6FA]">
      <div className="relative flex justify-center items-center h-[560px] bg-room bg-cover bg-center overflow-hidden">
        <div className="absolute inset-0 bg-black/50"></div>
        <h1 className="text-6xl font-primary text-white z-20 text-center relative">
          İletişim
        </h1>
      </div>
      <div className="py-16 px-4 max-w-6xl mx-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8">
          {/* İletişim Formu */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-3xl font-primary mb-6">Bize Ulaşın</h2>
            <form
              onSubmit={handleSubmit}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-semibold mb-2"
                >
                  Adınız
                </label>
                <input
                  type="text"
                  id="name"
                  name="from_name"
                  value={formData.from_name}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold mb-2"
                >
                  E-posta
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold mb-2"
                >
                  Mesajınız
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-accent text-white py-3 px-6 rounded-lg font-semibold"
                id="submit"
              >
                Gönder
              </button>
              {isSent && (
                <p className="text-green-600 mt-4">
                  Mesajınız başarıyla gönderildi!
                </p>
              )}
              {isError && (
                <p className="text-red-600 mt-4">
                  Mesaj gönderilirken bir hata oluştu.
                </p>
              )}
            </form>
          </div>

          {/* Otele Ait Bilgiler ve Harita */}
          <div className="lg:w-1/2 space-y-8">
            <div>
              <h2 className="text-3xl font-primary mb-6">Otel Bilgilerimiz</h2>
              <div className="bg-white p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-4">
                  İletişim Bilgileri
                </h3>
                <p className="text-gray-700 mb-4">
                  <strong>Adres:</strong> Alaçatı Mah., 12500 Sk. No: 60, Çeşme,
                  İzmir 35930, Türkiye
                </p>
                <p className="text-gray-700 mb-4">
                  <strong>Telefon:</strong> +90 530 270 20 15
                </p>
                <p className="text-gray-700 mb-4">
                  <strong>E-posta:</strong> solisalacati@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
