import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsPeople } from "react-icons/bs";
import { RoomContext } from "../context/RoomContext";
import { MdOutlineRoundedCorner } from "react-icons/md";
import Carousel from "./Carousel";

const Room = ({ room }) => {
  const { id, name, images, size, maxPerson, description } = room;
  const { priceInfo } = useContext(RoomContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getPriceInfo = (id) => {
    if (!priceInfo) return { nightlyRate: null, totalPrice: null };
    switch (id) {
      case 1:
        return {
          nightlyRate: priceInfo.dailyPrices.balkonlu,
          totalPrice: priceInfo.totalPrices.balkonlu,
        };
      case 2:
        return {
          nightlyRate: priceInfo.dailyPrices.bahceli,
          totalPrice: priceInfo.totalPrices.bahceli,
        };
      case 3:
        return {
          nightlyRate: priceInfo.dailyPrices.bodrum,
          totalPrice: priceInfo.totalPrices.bodrum,
        };
      default:
        return { nightlyRate: null, totalPrice: null };
    }
  };

  const { nightlyRate, totalPrice } = getPriceInfo(id);
  const { facilities } = room || {};

  const scrollToBookForm = () => {
    const bookForm = document.getElementById("book-form");
    if (bookForm) {
      bookForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleReserve = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      navigate(`/oda/${id}`);
    }, 1000);
  };

  return (
    <div className="relative bg-white shadow-2xl my-auto group rounded-2xl font-tertiary">
      <div className="overflow-hidden rounded-2xl" id="room-container">
        <Carousel images={images} />
      </div>
      <div className="bg-white shadow-lg max-w-[300px] mx-auto h-[60px] -translate-y-1/2 flex justify-center items-center tracking-[1px] font-semibold text-base rounded-2xl">
        <div className="flex justify-between w-[80%]">
          <div className="flex items-center gap-x-2">
            <div className="text-primary text-xl">
              <MdOutlineRoundedCorner />
            </div>
            <div className="flex gap-x-1">
              <div>
                {size}m<sup>2</sup>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="text-primary">
              <BsPeople className="text-[18px]" />
            </div>
            <div className="flex gap-x-1">
              <div>Maksimum</div>
              <div>{maxPerson}</div>
              <div>Kişilik</div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-4">
        <h3 className="h3">{name}</h3>
        <div>
          {facilities && facilities.length > 0 && (
            <div className="flex flex-wrap justify-center gap-4 my-4">
              {facilities.map((item, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <div>{item.icon}</div>
                  <div>{item.name}</div>
                </div>
              ))}
            </div>
          )}
        </div>

        {!nightlyRate && !totalPrice && (
          <p
            className="max-w-[300px] mx-auto mb-3 lg:mb-6 text-accent cursor-pointer"
            onClick={scrollToBookForm}
          >
            {description.slice(0, 100)}
          </p>
        )}

        {nightlyRate && totalPrice && (
          <div className="py-2 px-4 rounded-full flex justify-center items-center">
            <button
              type="submit"
              onClick={handleReserve}
              className="bg-accent hover:bg-accent-hover text-white font-semibold py-2 px-4 rounded-full flex items-center justify-center min-w-[200px] h-[48px]"
              id="submit"
            >
              {loading ? (
                <div className="loader w-6 h-6 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
              ) : (
                "Rezervasyon yap"
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Room;
