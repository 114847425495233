import React, { useContext, useState } from "react";
import AdultsDropdown from "../components/AdultsDropdown";
import CheckIn from "../components/CheckIn";
import { RoomContext } from "../context/RoomContext";

const BookForm = () => {
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [guestCount, setGuestCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleClick } = useContext(RoomContext);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!dates.startDate || !dates.endDate) {
      alert("Lütfen tarih aralığını seçin.");
      return;
    }

    if (dates.startDate === dates.endDate) {
      alert("Başlangıç ve bitiş tarihleri aynı olamaz.");
      return;
    }

    if (guestCount === 0) {
      alert("Lütfen misafir sayısını seçin.");
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      handleClick(e, dates, guestCount);
      document
        .querySelector("#room-container")
        ?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };

  return (
    <form
      className="h-auto w-full mx-auto flex flex-col lg:flex-row items-center lg:justify-between p-4 lg:space-x-4 font-tertiary"
      onSubmit={handleFormSubmit}
    >
      <div>
        <h1 className="font-semibold text-2xl font-tertiary mb-4 lg:mb-0">
          Rezervasyon
        </h1>
      </div>
      <div className="w-full lg:w-auto flex-grow">
        <CheckIn setDates={setDates} />
      </div>
      <div className="w-full lg:w-auto flex-grow mt-4 lg:mt-0">
        <AdultsDropdown setGuestCount={setGuestCount} />
      </div>
      <div className="w-full lg:w-auto mt-4 lg:mt-0 flex justify-center">
        <button
          className="bg-accent hover:bg-accent-hover text-white font-bold py-2 px-8 rounded-full flex items-center justify-center min-w-[100px] h-[48px]"
          type="submit"
          disabled={loading}
          id="submit"
        >
          {loading ? (
            <div className="loader w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
          ) : (
            "Ara"
          )}
        </button>
      </div>
    </form>
  );
};

export default BookForm;
