import React, { useState, useEffect } from "react";
import logo from "../assets/img/solis_logo.webp";
import { FaConciergeBell } from "react-icons/fa";

const Header = () => {
  const [header, setHeader] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHeader(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Helper function to format date in YYYY-MM-DD format
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Calculate check-in and check-out dates
  const today = new Date();
  const checkinDate = formatDate(today);
  const checkoutDate = formatDate(new Date(today.setDate(today.getDate() + 1)));

  // Construct the reservation URL with dynamic dates
  const reservationURL = `https://solis-alacati.hotelrunner.com/bv3/search?search=%7B%22checkin_date%22:%22${checkinDate}%22,%22checkout_date%22:%22${checkoutDate}%22,%22day_count%22:1,%22room_count%22:1,%22total_adult%22:2,%22total_child%22:0,%22rooms%22:%5B%7B%22adult_count%22:2,%22guest_count%22:2,%22child_count%22:0,%22child_ages%22:%5B%5D%7D%5D,%22guest_rooms%22:%7B%220%22:%7B%22adult_count%22:2,%22guest_count%22:2,%22child_count%22:0,%22child_ages%22:%5B%5D%7D%7D%7D`;

  return (
    <header
      className={`${
        header ? "bg-[#F3E8D7] py-4 shadow-lg" : "bg-transparent py-4"
      } fixed z-50 w-full transition-all duration-300`}
    >
      <div className="container mx-auto flex items-center justify-between relative">
        {/* Hamburger menu button for mobile view */}
        <button
          className={`${
            header ? "text-primary" : "text-white"
          } lg:hidden absolute right-4 `}
          onClick={toggleMenu}
          id="Hamburger"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        {/* Logo */}
        <div className="flex-grow flex justify-center lg:justify-start">
          <a href="/" aria-label="Home">
            <img className="w-[80px]" src={logo} alt="Solis Logo" />
          </a>
        </div>

        {/* Navigation links for large screens */}
        <nav
          className={`${
            header ? "text-primary" : "text-white"
          } hidden lg:flex gap-x-4 font-tertiary tracking-[3px] text-[15px] items-center uppercase lg:gap-x-8 font-medium`}
        >
          <a
            href="/"
            aria-label="Home"
            className="hover:text-accent transition"
          >
            Anasayfa
          </a>
          <a
            href="/about"
            aria-label="About"
            className="hover:text-accent transition"
          >
            Hakkımızda
          </a>
          <a
            href="https://solisalacati.my.canva.site/"
            target="_blank"
            rel="noreferrer"
            className="hover:text-accent transition"
            aria-label="Menu"
          >
            Menu
          </a>
          <a
            href="/contact"
            className="hover:text-accent transition"
            aria-label="Contact"
          >
            İletişim
          </a>

          {/* Rezervasyon Linki */}
          <a
            href={reservationURL}
            target="_blank"
            rel="noreferrer"
            className="flex items-center hover:text-accent transition"
            aria-label="Reservation"
          >
            <span className="mr-2">Rezervasyon</span>
            <FaConciergeBell />
          </a>
        </nav>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="fixed inset-0 bg-[#F3E8D7] z-50 flex flex-col items-center justify-center">
          {/* Close button */}
          <button
            className="absolute top-4 right-4 text-black"
            onClick={toggleMenu}
            id="Close"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <div className="flex flex-col items-center justify-center flex-grow">
            {/* Logo in mobile menu */}
            <a href="/" className="mb-6" aria-label="Home">
              <img className="w-[120px]" src={logo} alt="Solis Logo" />
            </a>
            {/* Navigation links */}
            <nav className="text-black flex flex-col items-center gap-y-6 text-xl font-tertiary tracking-[3px] text-[15px] font-medium">
              <a
                href="/"
                aria-label="Home"
                className="hover:text-accent transition"
              >
                Anasayfa
              </a>
              <a
                href="/about"
                aria-label="About"
                className="hover:text-accent transition"
              >
                Hakkımızda
              </a>
              <a
                href="https://solisalacati.my.canva.site/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-accent transition"
                aria-label="Menu"
              >
                Menu
              </a>
              <a
                href="/contact"
                aria-label="Contact"
                className="hover:text-accent transition"
              >
                İletişim
              </a>
              {/* Rezervasyon Linki Mobile Menüde */}
              <a
                href={reservationURL}
                target="_blank"
                rel="noreferrer"
                className="flex items-center hover:text-accent transition"
                aria-label="Reservation"
              >
                <span className="mr-2">Rezervasyon</span>
                <FaConciergeBell />
              </a>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
