import React from "react";
import hakkimizda from "../assets/img/about/hakkimizda.webp";
import hakkimizda2 from "../assets/img/about/hakkimizda2.webp";

const About = () => {
  return (
    <section className="font-tertiary bg-[#F6F6FA]">
      <div className="relative flex justify-center items-center h-[560px] bg-room bg-cover bg-center overflow-hidden">
        <div className="absolute inset-0 bg-black/50"></div>
        <h1 className="text-6xl font-primary text-white z-20 text-center relative">
          Hakkımızda
        </h1>
      </div>

      <div className="py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-primary mb-8">Hoşgeldiniz</h2>
          <p className="text-lg text-gray-700 mb-8">
            Butik otelimiz, eşsiz konfor ve zarafeti bir arada sunarak
            konuklarımıza unutulmaz bir deneyim yaşatmayı hedefler.
          </p>
          <img
            src={hakkimizda}
            alt="Hotel exterior"
            className="w-full h-auto rounded-lg shadow-lg mb-8"
          />

          <div className="flex flex-col md:flex-row md:justify-around mb-12 gap-4">
            <div className="bg-white p-6 rounded-lg shadow-lg mb-4 md:mb-0 md:w-1/3">
              <h3 className="text-2xl font-semibold mb-2">Misyonumuz</h3>
              <p className="text-gray-600">
                Misyonumuz, her bir misafirimize kişiye özel hizmet sunarak
                kendilerini evlerinde gibi hissetmelerini sağlamaktır. Konforlu
                odalarımız ve samimi atmosferimizle size en iyi deneyimi
                yaşatmayı hedefliyoruz.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg mb-4 md:mb-0 md:w-1/3">
              <h3 className="text-2xl font-semibold mb-2">Vizyonumuz</h3>
              <p className="text-gray-600">
                Vizyonumuz, butik otelcilik sektöründe lider konumda olmak ve
                misafirlerimize kaliteli hizmet anlayışımızla sektörün en iyisi
                olmaktır. Sürekli yenilikler ve mükemmellik hedefiyle hareket
                ediyoruz.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg md:w-1/3">
              <h3 className="text-2xl font-semibold mb-2">Değerlerimiz</h3>
              <p className="text-gray-600">
                Konuk memnuniyeti, kişisel dokunuş, kalite ve güvenilirlik
                değerlerimizdir. Bu değerler doğrultusunda, misafirlerimize her
                zaman en iyi hizmeti sunmak için çalışıyoruz.
              </p>
            </div>
          </div>

          <div className="text-center mb-16">
            <h2 className="text-3xl font-primary mb-6">Otel Tarihimiz</h2>
            <p className="text-lg text-gray-700 mb-8">
              2015 yılında kurulan butik otelimiz, misafirlerimize kaliteli ve
              kişiye özel hizmetler sunmaktadır.
            </p>
            <img
              src={hakkimizda2}
              alt="Hotel history"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
