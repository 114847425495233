import React from "react";
import logo from "../assets/img/solis_logo.webp";
import { FaEnvelope, FaPhone, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-[#1A1A1A] py-12 text-white font-tertiary relative">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          {/* Logo and Contact Information */}
          <div className="flex flex-col md:flex-row items-center md:items-start mb-8 md:mb-0 w-full md:w-1/3 text-center md:text-left">
            <a href="/" className="mb-6 md:mb-0" aria-label="Home">
              <img className="w-[120px]" src={logo} alt="Logo" />
            </a>
            <div className="text-center md:text-left md:ml-8">
              <p className="mb-2">
                Alaçatı Mah., 12500 Sk. No: 60, Çeşme, İzmir 35930, Türkiye
              </p>
              <p className="mb-2">Phone: +90 (530) 270 20 15</p>
              <p className="mb-6">Email: solisalacati@gmail.com</p>

              <div className="flex space-x-4 justify-center md:justify-start">
                <a
                  href="mailto:contact@yourdomain.com"
                  className="text-white hover:text-accent transition-colors duration-300"
                  aria-label="mail"
                >
                  <FaEnvelope size={30} />
                </a>
                <a
                  href="tel:+905302702015"
                  className="text-white hover:text-accent transition-colors duration-300"
                  aria-label="telephone"
                >
                  <FaPhone size={30} />
                </a>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div className="flex flex-col items-center w-full md:w-1/3 text-center mb-8 md:mb-0">
            <h4 className="font-bold mb-4">Hızlı Bağlantı</h4>
            <div className="flex flex-col space-y-2">
              <a
                href="/"
                className="block hover:text-accent transition-colors duration-300"
                aria-label="Home"
              >
                Anasayfa
              </a>
              <a
                href="/about"
                className="block hover:text-accent transition-colors duration-300"
                aria-label="About"
              >
                Hakkımızda
              </a>
              <a
                href="/contact"
                className="block hover:text-accent transition-colors duration-300"
                aria-label="Contact"
              >
                İletişim
              </a>
            </div>
          </div>

          {/* Social Media Links */}
          <div className="flex flex-col items-center w-full md:w-1/3 text-center">
            <div className="flex space-x-4 mb-6 justify-center">
              <a
                href="https://www.instagram.com/solisalacati/"
                className="text-white hover:text-accent transition-colors duration-300"
                aria-label="Instagram"
              >
                <FaInstagram size={40} />
              </a>
            </div>
            <p className="text-center mb-2 text-sm">
              <strong>© 2024 Solis Alaçatı, All rights reserved.</strong>
            </p>
          </div>
        </div>
      </div>

      {/* Developed By Text */}
      <div className="absolute bottom-0 left-0 right-0 text-center py-4">
        <a
          href="https://www.cemerdogdu.com"
          target="_blank"
          rel="noreferrer"
          className="block hover:text-accent transition-colors duration-300"
          aria-label="Developer"
        >
          Developed and Designed By Cem Erdoğdu
        </a>
      </div>
    </footer>
  );
};

export default Footer;
