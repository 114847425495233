import React, { useContext } from "react";
import { RoomContext } from "../context/RoomContext";
import Room from "../components/Room";
import { SpinnerDotted } from "spinners-react";

const Rooms = () => {
  const { rooms, loading, priceInfo } = useContext(RoomContext);

  // Extract guest count from priceInfo or handle it if you pass it as a prop
  const guestCount = priceInfo?.guestCount || 0;

  // Filter rooms based on guest count
  const filteredRooms =
    guestCount === 3 ? rooms.filter((room) => room.id !== 3) : rooms;

  return (
    <section className="py-12 lg:py-32">
      {loading && (
        <div className="h-screen fixed bottom-0 top-0 bg-black/90 w-full z-50 flex justify-center items-center">
          <SpinnerDotted color="white" />
        </div>
      )}
      <div className="container mx-auto lg:px-0">
        <div className="text-center">
          <div className="font-tertiary uppercase text-[15px] tracking-[6px]">
            Solis Alaçatı
          </div>
          <h2 className="font-primary text-[45px] mb-4">Odalar</h2>
        </div>
        {/* grid */}
        <div
          className={`grid grid-cols-1 gap-[30px] ${
            filteredRooms.length === 1
              ? "lg:grid-cols-1"
              : filteredRooms.length === 2
              ? "lg:grid-cols-2"
              : "lg:grid-cols-3"
          } lg:max-w-none lg:mx-0`}
        >
          {filteredRooms.map((room) => (
            <Room
              room={room}
              key={room.id}
              className="max-w-[400px] mx-auto lg:max-w-[500px]"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Rooms;
