import React, { createContext, useState } from "react";
import { roomData } from "../data";

export const RoomContext = createContext();

const RoomProvider = ({ children }) => {
  const [rooms, setRooms] = useState(roomData);
  const [priceInfo, setPriceInfo] = useState(null);
  const [reservationDetails, setReservationDetails] = useState(null);

  const calculatePrices = (dates, guestCount) => {
    const { startDate, endDate } = dates;

    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = end.getTime() - start.getTime();
    const dayCount = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const getPrice = (month, guestCount) => {
      let basePrice;
      switch (month) {
        case 6: // July
          basePrice = { bodrum: 4000, bahceli: 5000, balkonlu: 6000 };
          break;
        case 7: // August
          basePrice = { bodrum: 4500, bahceli: 5500, balkonlu: 6500 };
          break;
        case 8: // September
          basePrice = { bodrum: 3000, bahceli: 4000, balkonlu: 5000 };
          break;
        default:
          return null;
      }

      const extraPersonCharge = guestCount > 2 ? 1500 : 0;

      return {
        balkonlu: basePrice.balkonlu + extraPersonCharge,
        bahceli: basePrice.bahceli + extraPersonCharge,
        bodrum: basePrice.bodrum + extraPersonCharge,
        basePrice: basePrice,
        extraPersonCharge: extraPersonCharge,
      };
    };

    const startMonth = start.getMonth();
    const prices = getPrice(startMonth, guestCount);

    const dailyPrices = {
      balkonlu: prices.balkonlu,
      bahceli: prices.bahceli,
      bodrum: prices.bodrum,
    };

    const totalPrices = {
      balkonlu: dailyPrices.balkonlu * dayCount,
      bahceli: dailyPrices.bahceli * dayCount,
      bodrum: dailyPrices.bodrum * dayCount,
    };

    return { dailyPrices, totalPrices, dates, guestCount };
  };

  const handleClick = (e, dates, guestCount) => {
    e.preventDefault();

    const calculatedPrices = calculatePrices(dates, guestCount);
    if (calculatedPrices) {
      const updatedRooms = rooms.map((room) => {
        const updatedRoom = { ...room };

        if (room.id === 3)
          updatedRoom.price = calculatedPrices.totalPrices.bodrum;
        if (room.id === 2)
          updatedRoom.price = calculatedPrices.totalPrices.bahceli;
        if (room.id === 1)
          updatedRoom.price = calculatedPrices.totalPrices.balkonlu;

        return updatedRoom;
      });

      setRooms(updatedRooms);
      setPriceInfo(calculatedPrices);
      setReservationDetails(calculatedPrices);
    }
  };

  return (
    <RoomContext.Provider
      value={{ rooms, handleClick, priceInfo, reservationDetails }}
    >
      {children}
    </RoomContext.Provider>
  );
};

export default RoomProvider;
