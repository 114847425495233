import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { AiOutlineZoomIn, AiOutlineClose } from "react-icons/ai";

const Loader = () => (
  <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
    <svg
      className="animate-spin h-10 w-10 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4zm8 8a8 8 0 01-8-8h4a4 4 0 004 4v4zm8-8a8 8 0 01-8 8v-4a4 4 0 004-4h4z"
      ></path>
    </svg>
  </div>
);

const Carousel = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [loading, setLoading] = useState(true);

  if (images.length === 0) {
    return <div>No images available</div>; // Handle the case where there are no images
  }

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="relative">
      <div className="relative w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[300px] rounded-lg overflow-hidden">
        {loading && <Loader />}
        <div
          style={{ backgroundImage: `url(${images[currentIndex]})` }}
          className={`w-full h-full bg-center bg-cover bg-no-repeat duration-500 relative cursor-pointer rounded-lg ${
            isZoomed ? "opacity-30" : ""
          }`}
        >
          <button
            onClick={() => setIsZoomed(true)}
            className={`absolute top-2 right-2 text-white bg-black/50 p-2 rounded-full ${
              isZoomed ? "hidden" : ""
            }`}
            id="Test"
          >
            <AiOutlineZoomIn size={20} />
          </button>
          <div className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white bg-black/50 p-2 rounded-full cursor-pointer">
            <BsChevronCompactLeft onClick={prevSlide} size={24} />
          </div>
          <div className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white bg-black/50 p-2 rounded-full cursor-pointer">
            <BsChevronCompactRight onClick={nextSlide} size={24} />
          </div>
          <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {images.map((_, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => goToSlide(slideIndex)}
                className={`text-xl cursor-pointer ${
                  slideIndex === currentIndex ? "text-accent" : "text-white"
                }`}
              >
                <RxDotFilled />
              </div>
            ))}
          </div>
        </div>
      </div>

      {isZoomed && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="relative w-full max-w-screen-sm h-full flex items-center justify-center rounded-lg">
            {loading && <Loader />}
            <img
              src={images[currentIndex]}
              alt={`Slide ${currentIndex}`}
              className="w-auto h-auto max-w-full max-h-screen object-contain rounded-lg"
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
            />
            <button
              onClick={() => setIsZoomed(false)}
              className="absolute top-2 right-2 text-white text-lg bg-black/30 p-2 rounded-full"
              id="Test"
            >
              <AiOutlineClose />
            </button>
            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-2xl bg-black/30 p-2 rounded-full"
              id="Test"
            >
              <BsChevronCompactLeft />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-2xl bg-black/30 p-2 rounded-full"
              id="Test"
            >
              <BsChevronCompactRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
