import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { RoomContext } from "../context/RoomContext";
import ScrollToTop from "../components/ScrollToTop";
import { FaCheck } from "react-icons/fa";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import "swiper/css";
import "swiper/css/navigation";
import ImageCarousel from "../components/Carousel";

const RoomDetails = () => {
  const { rooms, reservationDetails } = useContext(RoomContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loadingReserve, setLoadingReserve] = useState(false); // Rezervasyon Yap butonu için loading state

  const room = rooms.find((room) => room.id === Number(id));
  const { name, facilities, hotelfacilities, images } = room || {};
  const { dates, guestCount } = reservationDetails || {};

  useEffect(() => {
    if (!reservationDetails) {
      navigate("/");
    }
  }, [reservationDetails, navigate]);

  const formatDates = (startDate, endDate) => {
    if (!startDate || !endDate) return "";
    const start = new Date(startDate);
    const end = new Date(endDate);
    return `${format(start, "d MMMM", { locale: tr })} - ${format(
      end,
      "d MMMM",
      { locale: tr }
    )}`;
  };

  if (!room) {
    return <div>Oda bulunamadı.</div>;
  }

  const handleBookNowClick = () => {
    setLoadingReserve(true);

    // Tarihleri ve misafir sayısını URL parametrelerine ekle
    const checkinDate = format(new Date(dates?.startDate), "yyyy-MM-dd");
    const checkoutDate = format(new Date(dates?.endDate), "yyyy-MM-dd");
    const dayCount =
      (new Date(dates?.endDate) - new Date(dates?.startDate)) /
      (1000 * 60 * 60 * 24);
    const roomCount = 1; // Varsayılan oda sayısı
    const totalAdult = guestCount;
    const totalChild = 0; // Varsayılan çocuk sayısı
    const guestRooms = `[{"adult_count":${totalAdult},"guest_count":${totalAdult},"child_count":${totalChild},"child_ages":[]}]`;

    const url = `https://solis-alacati.hotelrunner.com/bv3/search?search=%7B%22checkin_date%22:%22${checkinDate}%22,%22checkout_date%22:%22${checkoutDate}%22,%22day_count%22:${dayCount},%22room_count%22:${roomCount},%22total_adult%22:${totalAdult},%22total_child%22:${totalChild},%22rooms%22:${guestRooms},%22guest_rooms%22:%7B%220%22:%7B%22adult_count%22:${totalAdult},%22guest_count%22:${totalAdult},%22child_count%22:${totalChild},%22child_ages%22:%5B%5D%7D%7D%7D`;

    setTimeout(() => {
      window.location.href = url;
      setLoadingReserve(false);
    }, 1000);
  };

  return (
    <section className="font-tertiary bg-[#F6F6FA]">
      <ScrollToTop />
      <div className="bg-room bg-cover bg-center h-[560px] relative flex justify-center items-center">
        <div className="absolute w-full h-full bg-black/50"></div>
        <h1 className="text-6xl text-white z-20 font-primary text-center">
          {name} Özellikleri
        </h1>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row h-full py-24">
          {/* Rezervasyon Bilgileri ve Butonu Üstte */}
          <div className="w-full lg:w-2/5 lg:order-1 mb-8 lg:mb-0">
            <div className="py-10 px-8 bg-gradient-to-r from-accent to-accent rounded-2xl shadow-lg">
              <div className="flex flex-col space-y-6 mb-6">
                <h3 className="text-2xl font-semibold text-white">
                  Rezervasyon Bilgileri
                </h3>
                <div className="text-white">
                  <p className="text-lg font-medium">
                    Seçilen Tarihler:{" "}
                    {formatDates(dates?.startDate, dates?.endDate)}
                  </p>
                  <p className="text-lg font-medium">
                    Misafir Sayısı: {guestCount}
                  </p>
                </div>

                {/* Rezervasyon Yap Butonu */}
                <button
                  onClick={handleBookNowClick}
                  className="mt-6 bg-white text-black py-3 px-6 rounded-lg shadow-md hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center min-w-[120px] h-[48px]"
                  disabled={loadingReserve}
                  id="reservation"
                >
                  {loadingReserve ? (
                    <div className="loader w-6 h-6 border-4 border-t-transparent border-accent rounded-full animate-spin"></div>
                  ) : (
                    "Rezervasyon Yap"
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Oda Bilgileri ve Diğer İçerikler */}
          <div className="w-full lg:w-[60%] px-6">
            <h2 className="h2">{name}</h2>
            <ImageCarousel images={images} />
            <div className="mt-12">
              <h3 className="text-2xl font-bold mb-6">Oda Özellikleri</h3>
              <div className="grid grid-cols-3 gap-6 mb-12">
                {facilities.map((item, index) => (
                  <div
                    className="relative flex items-center justify-center group"
                    key={index}
                  >
                    {/* Icon */}
                    <div className="text-3xl">{item.icon}</div>

                    {/* Tooltip */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md">
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-12">
              <h3 className="text-2xl font-bold mb-6">Otel Özellikleri</h3>
              <div className="grid grid-cols-3 gap-6 mb-12">
                {hotelfacilities.map((item, index) => (
                  <div
                    className="relative flex items-center justify-center group"
                    key={index}
                  >
                    {/* Icon */}
                    <div className="text-3xl">{item.icon}</div>

                    {/* Tooltip */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded-md">
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-12 mb-12 lg:mb-0">
              <h3 className="h3 mb-6">Otel Kuralları</h3>

              <ul className="flex flex-col gap-y-4">
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Giriş: 14:00
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Çıkış: 12:00
                </li>
                <li className="flex items-center gap-x-4">
                  <FaCheck className="text-accent" />
                  Evcil hayvan kabul edilmez.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RoomDetails;
