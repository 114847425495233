import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper";
import Img1 from "../assets/img/heroSlider/1.webp";
import Img2 from "../assets/img/heroSlider/2.webp";
import Img3 from "../assets/img/heroSlider/3.webp";
import Img4 from "../assets/img/heroSlider/4.webp";
import { FaPhone, FaWhatsapp, FaInstagram } from "react-icons/fa";

const slides = [
  {
    title: "Yazı Solis Alaçatı'da hisset",
    bg: Img1,
    btnText: "Hemen Ara",
    icon: <FaPhone />,
    link: "tel:+905302702015",
  },
  {
    title: "Yazı Solis Alaçatı'da hisset",
    bg: Img2,
    btnText: "Whatsapp",
    icon: <FaWhatsapp />,
    link: "https://wa.me/905302702015",
  },
  {
    title: "Yazı Solis Alaçatı'da hisset",
    bg: Img3,
    btnText: "Instagram",
    icon: <FaInstagram />,
    link: "https://www.instagram.com/solisalacati",
  },
  {
    title: "Yazı Solis Alaçatı'da hisset",
    bg: Img4,
    btnText: "Hemen Ara",
    icon: <FaPhone />,
    link: "tel:+905302702015",
  },
];

const HeroSlider = () => {
  return (
    <Swiper
      modules={[EffectFade, Autoplay]}
      effect={"fade"}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      className="heroSlider h-[600px] lg:h-[860px]"
    >
      {slides.map((slide, index) => {
        const { title, bg, btnText, icon, link } = slide;
        return (
          <SwiperSlide
            className="h-full relative flex justify-center items-center"
            key={index}
          >
            <div className="z-20 text-white/80 text-center">
              <div
                className="uppercase font-tertiary tracking-[6px] mb-5"
                id="book-form"
              >
                Solis Alaçatı
              </div>
              <h1 className="text-[32px] font-primary uppercase tracking-[2px] max-w-[920px] lg:text-[68px] leading-tight mb-6">
                {title}
              </h1>
              <a href={link} className="block w-fit mx-auto">
                <button
                  className="btn btn-lg btn-primary rounded-full gap-2 flex items-center hover:bg-accent-hover"
                  id="button"
                >
                  {btnText}
                  {icon}
                </button>
              </a>
            </div>
            <div className="absolute top-0 w-full h-full">
              <img className="object-cover h-full w-full" src={bg} alt="" />
            </div>
            <div className="absolute w-full h-full bg-black/50"></div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroSlider;
